import { Grid, Typography } from "@mui/material";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const OrderStatusComponent = (props) => {
    const { orderStatus, orderedOn, OrderPreferableTiming } = props;

    const getOrderStatus = () => {
        let orderStatusText = ""
        let OrderHelptext = ""
        switch (orderStatus) {
            case "1":
                orderStatusText = "ordered";
                OrderHelptext = "Your Order Is Placed Successfully, Waiting for seller confirmation"
                break
            case "2":
                orderStatusText = "Order-confirmed";
                OrderHelptext = "Your Order Is PLaced Successfully, Waiting For shipment"
                break
            case "3":
                orderStatusText = "Order Rejected";
                OrderHelptext = "Your Order Is rejected by the Seller due to product Unavailabilty"
                break
            case "4":
                orderStatusText = "Awaiting Shipment";
                OrderHelptext = "Order is picked, packed, and ready for pickup by the courier/postal service"
                break
            case "5":
                orderStatusText = "Shipped";
                OrderHelptext = "Order is handed off to the courier/postal service for delivery"
                break
            case "6":
                orderStatusText = "Order arrived at nearest hub to you";
                OrderHelptext = "Order is handed off to the courier/postal service for delivery"
                break
            case "7":
                orderStatusText = "Out for Delivery";
                OrderHelptext = "Package is in transit with the courier/postal service"
                break
            case "8":
                orderStatusText = "Delivered";
                OrderHelptext = "Ypur Package is has been delivered"
                break
            case "9":
                orderStatusText = "Completed";
                OrderHelptext = "Order has been delivered and no further action is required"
                break

            case "10":
                orderStatusText = "Requested for Cancellation";
                OrderHelptext = "Your request for cancellation is initiated"
                break
            case "11":
                orderStatusText = "Cancellation Request Rejected";
                OrderHelptext = "Seller rejects the Customer requests to cancel the order "
                break
            case "12":
                orderStatusText = "Cancellation Request accepted";
                OrderHelptext = "Your cancellation request is accepted by the seller cancellation is processing."
                break
            case "13":
                orderStatusText = "Order Cancelled";
                OrderHelptext = "Your Order is cancelled"
                break
            case "14":
                orderStatusText = "Return Requested";
                OrderHelptext = "Requested for return the product, seller need to accept your request."
                break
            case "15":
                orderStatusText = "Return Requested Rejected";
                OrderHelptext = "Your request for return product is rejected by the seller."
                break
            case "16":
                orderStatusText = "Return Request Accepted";
                OrderHelptext = "Your return request is accepted by the seller, our delevery partner will take your order"
                break
            case "17":
                orderStatusText = "Return Scheduled";
                OrderHelptext = "Delvery boy is ready to pickup the item from you and this information is updated "
                break
            case "18":
                orderStatusText = "Return Shipment";
                OrderHelptext = "your returned items are picked up by our delivery partner and transeferred to the seller"
                break
            case "19":
                orderStatusText = "Return Received";
                OrderHelptext = "Returned package has been received by the seller"
                break
            case "20":
                orderStatusText = "Return Processing";
                OrderHelptext = "Seller receives the returned item(s), verifies condition, and issues a refund (or exchange). "
                break

            case "21":
                orderStatusText = "Return Processed: ";
                OrderHelptext = "Seller has processed the return and issued a refund (or exchange)"
                break
            default:
                orderStatusText = "Order Status Not found";
                OrderHelptext = "Order transmission details are unavailable"
                break
        }
        return orderStatusText
    }

    const getPreferredTiming = (tomeCode) => {
        let timing = "";
        switch (tomeCode) {
            case "t1":
                timing = "Between 6AM to 10AM"
                break
            case "t2":
                timing = "Between  10AM to 2PM"
                break
            case "t3":
                timing = "Between  2PM to 6pm"
                break
            case "t4":
                timing = "Between  6pm to 10PM"
                break
            default:
                timing = "Any Time"
        }
        return timing
    }

    return (
        <Grid item xs={12} >
            <Grid item xs={12} md={5} >
                <Typography component="div" variant="subtitle2" className="ps-2">
                    <span className="ord-caption"> Prefered Time : </span>
                    {getPreferredTiming(OrderPreferableTiming)}
                </Typography>
            </Grid>
            <Divider component="li" />
            <Grid item xs={12} md={5} >
                <Typography component="div" variant="subtitle2" className="ps-2">
                    <span className="ord-caption"> {getOrderStatus()
                    } </span>
                    on {orderedOn}
                </Typography>
                {/* <Typography variant="subtitle" component="h4">
                    {getOrderStatus()
                    }
                </Typography> */}
            </Grid>
            <Divider component="li" />
            {/* <Grid item xs={12} >
                <Typography variant="subtitle2" component="div">
                  on {orderedOn}
                </Typography>
            </Grid> */}
        </Grid>
    )
}
export default OrderStatusComponent;