import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ArchiveIcon from '@mui/icons-material/Archive';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Paper from '@mui/material/Paper';
import { AccountCircle, Home, ShoppingCartCheckout } from '@mui/icons-material';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ProductListComponent } from '../products/productlist';
import CartList from '../cart/cartlist';
import OrdersList from '../orders/orders';
import ProfileComponent from '../profile/profile';
import LoginPage from '../login/login';
import SignIn from '../login/login';
import SignUp from '../login/register';
import Checkout from '../checout/checkout';
import ProductDetails from '../productDetails/productDetails';
import PaymentsComponent from '../payments/payments';
import OrderDetails from '../orderdetails/orderDetails';
import ForgotPassword from '../profile/forgotpassword';
import ChangePassword from '../profile/changepassword';
import MyAddress from '../profile/myAddress';
import CategoryIcon from '@mui/icons-material/Category';
import CategoriesPage from '../categories/categories';
import ProductsBysubCatId from '../subcategories/productsbySubCatId';
import Sellers from '../sellers/sellers';
import SaveForLater from '../saveforlater/saveforlater';
import InvalidUrlPage from '../invalidurlpage/invalidurlpage';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import { HomeComponent } from '../home/homeComponent';
import HelpAndSupport from '../profile/helpandsupport';
import './menu.css'
import ProductsCatId from '../subcategories/productsbycatid';

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const ref = React.useRef(null);
  const [messages, setMessages] = React.useState();
  const navigate = useNavigate()

  const onLogoutClick = () => {
    localStorage.removeItem("userid")
  }

  React.useEffect(() => {
  }, []);

  return (
    <Box sx={{ pb: 7 }} ref={ref} className="scroll-y">
      <CssBaseline />
      <Routes>
        <Route path='' element={<HomeComponent />}></Route>
        <Route path='/cart' element={<CartList />}></Route>
        <Route path='/profiles' element={<ProfileComponent />}></Route>
        <Route path='/register' element={<SignUp />}></Route>
        <Route path='/orders' element={<OrdersList />}></Route>
        <Route path='/orders/:orderId' element={<OrderDetails />}></Route>
        <Route path='/checkout' element={<Checkout />}></Route>
        <Route path='/payments' element={<PaymentsComponent />}></Route>
        <Route path='/login' element={<LoginPage />}></Route>
        <Route path='/changePass' element={<ChangePassword />}></Route>
        <Route path='/forgotPassword' element={<ForgotPassword />}></Route>
        <Route path='/products/:productId' element={<ProductDetails />}></Route>
        <Route path='/myaddress/:addressId' element={<MyAddress />}></Route>
        <Route path='/categories' element={<CategoriesPage />} />
        <Route path='/productsbySubCatId/:id' element={<ProductsBysubCatId />} />
        <Route path='/productsbyCatId/:id' element={<ProductsCatId />} />

        <Route path='/sellers' element={<Sellers />} />
        <Route path='/saveforlater' element={<SaveForLater />} />
        <Route path='/viewproducts/:searchText' element={<ProductListComponent />} />
        <Route path='/viewproducts' element={<ProductListComponent />} />
        <Route path='/helpandsupport' element={<HelpAndSupport />} />
        <Route path='*' element={<InvalidUrlPage />} />
      </Routes>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            navigate("/" + newValue)
            setValue(newValue)
          }}
        >
          <BottomNavigationAction label="Home" value="" icon={<Home />} />
          <BottomNavigationAction label="Products" value={"viewproducts"} icon={<LocalMallIcon />} />
          <BottomNavigationAction label="Orders" value={"orders"} icon={<ArchiveIcon />} />
          {/* <BottomNavigationAction label="Profile" value="Profiles" icon={<AccountCircle />} /> */}
          <BottomNavigationAction label="categories" value="categories" icon={<CategoryIcon />} />
          <BottomNavigationAction label="Stroes" value="sellers" icon={<StorefrontIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
