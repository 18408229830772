import { Call, DateRange, Email, House, LocationCity, PermIdentity, Place } from "@mui/icons-material"
import { CardContent, Container, Card, Grid, Avatar, List, ListItem, ListItemAvatar, ListItemText, Stack, Button } from "@mui/material"
import axios from "axios"
import React, { useState } from "react"
import './profile.css'
import { useNavigate } from "react-router-dom"
import Modal from 'react-bootstrap/Modal';

import { FaInfoCircle } from "react-icons/fa";
function ProfileComponent() {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function Path() {
        navigate('/changePass')
    }

    const onLogoutClick = () => {
        localStorage.removeItem("userid")
        navigate('/login')
        handleClose();
    }

    function Path2() {
        navigate('/forgotPassword')
    }

    React.useEffect(
        () => {
            if (localStorage.getItem("userid")) {
            } else {
                navigate("/login")
            }
            axios.post("http://asvmall.com/api/getuserbyid.php", {
                "main_id": "8989877hhftgh67",
                "user_id": localStorage.getItem("userid")
            }).then((result) => {
                if (result.status === 200 && result.request.readyState === 4) {
                    setUserData(result.data[0])
                } else {
                    userData([])
                }
            }).catch(e => {
                alert("internal server error: ", e)
            })
        }, []
    )

    return (<div>
        <div>
            <Container component={"main"} maxWidth="lg">
                <h3>Profile</h3>
                <Card>
                    <CardContent align="center">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={4} className="settingsoption1">
                                <Avatar style={{ margin: 'auto' }} sx={{ width: 100, height: 100 }}>{userData.UserFirstName ? userData.UserFirstName[0].toUpperCase() : "A"}</Avatar>
                                <Stack direction="row" spacing={2}>
                                    <List>
                                        <ListItem>
                                            <Button variant="text" color="info">Change Image</Button>
                                        </ListItem>
                                        <ListItem>
                                            <Button onClick={() => Path()} variant="text" color="primary">Change Password</Button>
                                        </ListItem>
                                        <ListItem>
                                            <Button onClick={() => Path2()} variant="text">Forgot Password</Button>
                                        </ListItem>
                                        <ListItem>
                                            <Button onClick={() => navigate(`/myaddress/${localStorage.getItem("userid")}`)} variant="text">My Address</Button>
                                        </ListItem>
                                        <ListItem>
                                            <Button onClick={() => navigate(`/saveforlater`)} variant="text">Save for later Items</Button>
                                        </ListItem>
                                        <ListItem>
                                            <Button onClick={() => navigate(`/helpandsupport`)} variant="text">Help & Support</Button>
                                        </ListItem>
                                        <ListItem>
                                            <Button onClick={handleShow}>LogOut</Button>
                                        </ListItem>
                                    </List>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8} className="settingsoption2">
                                <List spacing={2}>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PermIdentity />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Full Name:' secondary={userData.UserFirstName + userData.UserLastName} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar  >
                                                <Call />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Mobile no:' secondary={userData.UserPhone}></ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar  >
                                                <Email />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Email:' secondary={userData.UserEmail}></ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar  >
                                                <House />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Address:' secondary={userData.UserAddress}></ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar  >
                                                <LocationCity />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='City' secondary={userData.UserCity}></ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar  >
                                                <Place />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='State' secondary={userData.UserState}></ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar  >
                                                <DateRange />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary='Date' secondary={userData.UserRegistrationDate}></ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title> <FaInfoCircle /> Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you want logoout</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={onLogoutClick}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>)

}
export default ProfileComponent