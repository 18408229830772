// import { sendOTP, verifyOTP, resendOTP } from "email-otp-verify";
import { Button, CardContent, Container, FormControl, FormGroup, Grid, Stack, TextField } from "@mui/material"
import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Card } from "react-bootstrap"
import { Navigate, useNavigate } from "react-router-dom"
import Box from '@mui/material/Box';
import emailjs from '@emailjs/browser';
import bcrypt from 'bcryptjs'

function ForgotPassword() {
    const [userDetails, setUserDetails] = useState({})
    const [inputEmail, setInputEmail] = useState("")
    const [sentMail, setSentMail] = useState(false)
    const [otpvalue, setOtpvalue] = useState("5686748$^%&#$#^@*^")
    const [inputOTP, setInputOTP] = useState("")
    const [otpSuccess, setOtpSuccess] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errorCodes, setErroCodes] = useState({
        inputEmail: false,
        inputEmailCode: "Enter valid Email address",
        contactNumber: false,
        contactNumberCode: "Contact Number should have 10digits",
        password: false,
        passwordCode: "Password should have 6 charecters",
        confirmPassword: false,
        confirmPasswordCode: "confirm password should match with password",
    })
    const [logginErrors, setLogginErrors] = useState({
        show: false,
        message: "Invalid UserName or Password"
    })
    const navigate = useNavigate();
    const form = useRef();

    const onChangeEmail = (e) => {
        // 2. Email validation using a stricter regular expression:
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Allows standard email format
        const isValidEmail = emailRegex.test(e.target.value);
        // 3. Update state and provide feedback based on validation:
        if (e.target.value === "") {
            setInputEmail("");
            setErroCodes((prev) => ({ ...prev, inputEmail: true, inputEmailCode: "Email is required" }));
        } else if (isValidEmail) {
            setInputEmail(e.target.value.toLowerCase()); // Convert to lowercase (optional)
            setErroCodes((prev) => ({ ...prev, inputEmail: false, inputEmailCode: "" }));
        } else {
            setInputEmail(e.target.value.toLowerCase()); // Convert to lowercase (optional)
            setErroCodes((prev) => ({ ...prev, inputEmail: true, inputEmailCode: "Invalid email format" }));
        }
    };

    const onPasswordChange = (e) => {
        if (logginErrors.show) {
            setLogginErrors(prev => ({ ...prev, show: false }))
        }
        // 3. Update state and provide feedback based on validation:
        if (e.target.value.length < 6) {
            setPassword(e.target.value);
            setErroCodes(prev => ({ ...prev, password: true, passwordCode: "Password Should have atleast 6 charecters" }))
        }
        else if (e.target.value.length > 20) {
            setErroCodes(prev => ({ ...prev, password: true, passwordCode: "Password Should not more than 20 charecters" }))
        }
        else {
            setPassword(e.target.value);
            setErroCodes(prev => ({ ...prev, password: false, passwordCode: "" }))
        }
        if ((e.target.value.length >= 6) && (e.target.value == confirmPassword)) {

            setErroCodes(prev => ({ ...prev, confirmPassword: false, confirmPasswordCode: "" }))
        }

    }
    const onConfirmPasswordChange = (e) => {
        if (logginErrors.show) {
            setLogginErrors(prev => ({ ...prev, show: false }))
        }
        // 3. Update state and provide feedback based on validation:
        if ((e.target.value.length >= 6) && (e.target.value == password)) {
            setConfirmPassword(e.target.value);
            setErroCodes(prev => ({ ...prev, confirmPassword: false, confirmPasswordCode: "" }))
        }
        else {
            setConfirmPassword(e.target.value);
            setErroCodes(prev => ({ ...prev, confirmPassword: true, confirmPasswordCode: "Confirm Password Should match with Password" }))
        }
    }

    const handleSubmit = (e) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Allows standard email format
        e.preventDefault();
        if (!emailRegex.test(inputEmail)) {
            setErroCodes(prev => ({ ...prev, inputEmail: true, inputEmailCode: "Enter valid Email address" }))
            return
        }
        const jsonData = {
            "main_id": "8989877hhftgh67",
            "user_mail": inputEmail
        }
        axios.post("http://asvmall.com/api/users/isusermailpresent.php", jsonData).then(
            (result) => {
                if (result.data == true) {
                    setSentMail(true);
                    let randomNumber = Math.floor(Math.random() * 1000000);
                    document.getElementById('hidden-otp').value = `This email consists of One-Time-Password for resetting your password otp-${randomNumber}. \nThis is valid for 5 minutes only`
                    let hashedrandomnumber = bcrypt.hashSync(randomNumber.toString(), '$2a$10$CwTycUXWue0Thq9StjUM0u')
                    setOtpvalue(hashedrandomnumber)
                    emailjs
                        .sendForm('service_m3n3haj', 'template_seuau5c', form.current, {
                            publicKey: 'KShC9C9vYPFgpet9P',
                        })
                        .then(
                            () => {
                                //console.log('SUCCESS!');
                            },
                            (error) => {
                                //console.log('FAILED...', error.text);
                                setSentMail(false);
                            }
                        );
                    return
                } else {
                    setErroCodes(prev => ({ ...prev, inputEmail: true, inputEmailCode: "Entered Email Address is not linked" }))
                }
            }
        ).catch(
            (err) => {
                setErroCodes(prev => ({ ...prev, inputEmail: true, inputEmailCode: "Please Tryu again later  " + err }))
            })
    }

    const onSubmitOtpClick = () => {
        let hashedrandomnumber = bcrypt.hashSync(inputOTP.toString(), '$2a$10$CwTycUXWue0Thq9StjUM0u')
        if (otpvalue === hashedrandomnumber) {
            setOtpSuccess(true)
        } else {
            setErroCodes(prev => ({ ...prev, contactNumber: true, contactNumberCode: "Please enter Valid OTP" }))
        }
    }
    const onChangeInptuOTP = (e) => {
        const nameRegex = /^[0-9]+$/;
        if (e.target.value.length <= 6) {
            setInputOTP(e.target.value)
        } else {
            return
        }
        if (e.target.value == "") {
            setInputOTP(e.target.value);
            setErroCodes(prev => ({ ...prev, contactNumber: true, contactNumberCode: "OTP should have 6 digits" }))
        }
        else if (nameRegex.test(e.target.value)) {
            if (e.target.value.length < 6) {
                setInputOTP(e.target.value);
                setErroCodes(prev => ({ ...prev, contactNumber: true, contactNumberCode: "Please enter Valid OTP" }))
            }
            else if (e.target.value.length === 6) {
                setErroCodes(prev => ({ ...prev, contactNumber: false }))
            }
            else {
                return
            }

        } else {
            return
        }
    }
    const validatepasswordsFields = () => {
        let isvalid = true
        if (password.length < 6) {
            setErroCodes(prev => ({ ...prev, password: true, passwordCode: "password Should have 6 charecters atleast" }))
            isvalid = false
        } if ((password !== confirmPassword) || (confirmPassword.length < 6)) {
            setErroCodes(prev => ({ ...prev, confirmPassword: true, confirmPasswordCode: "confirm Password Should Match PAssword" }))
            isvalid = false
        }
        return isvalid
    }
    const handleSubmitPassword = (e) => {
        e.preventDefault();
        const isvalidPasswordFields = validatepasswordsFields()
        if (isvalidPasswordFields) {
            const jsonData = {
                main_id: "8989877hhftgh67",
                UserId: localStorage.getItem("userid"),
                UserEmail: inputEmail,
                UserNewPassword: bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
            }
            axios.post("http://asvmall.com/api/users/forgotpassword.php", jsonData).then(
                (result) => {
                    if (result.status == 200 && result.request.readyState == 4) {

                        if (result.data == 1) {
                            alert("Password update Successfull")

                            navigate("/profiles")
                        } else {
                            setLogginErrors({ show: true, message: "Error occured while updating new password , seems like similar to the old password" })
                        }
                    }
                }
            ).catch(
                (err) => {
                    setLogginErrors({ show: true, message: "Error Accured " + err })
                })
        }
    }
    useEffect(() => {
        axios.post("http://asvmall.com/api/getuserbyid.php", {
            "main_id": "8989877hhftgh67",
            "user_id": localStorage.getItem("userid")
        }).then((result) => {

            if (result.status === 200 && result.request.readyState === 4) {

                setUserDetails(result.data[0])
            }
        }).catch(e => {
            alert("internal server error: ", e)
        })
    }, [])

    return (<div>
        <Container Container="main" maxWidth="xs">
            <Box component="form" hidden={otpSuccess} ref={form} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <h4>Forgot Password</h4>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="user_email"
                    label="Enter Your Email "
                    name="user_email"
                    autoComplete="email"
                    value={inputEmail}
                    autoFocus
                    disabled={sentMail}
                    error={errorCodes.inputEmail}
                    helperText={errorCodes.inputEmail && errorCodes.inputEmailCode}
                    onChange={onChangeEmail}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="user_message"
                    label="user Message"
                    type="password"
                    hidden
                    id="hidden-otp"
                    autoComplete="current-password"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="user_name"
                    label="user name"
                    value={userDetails.UserFirstName}
                    hidden
                    id="user_name"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={sentMail}
                >
                    Get OTP
                </Button>
                <p>An otp has been sent to the above email</p>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="otp_submit"
                    label="Enter 6 digits OTP"
                    name="otp_submit"
                    maxLength={6}
                    autoFocus
                    hidden={!sentMail}
                    value={inputOTP}
                    error={errorCodes.contactNumber}
                    helperText={errorCodes.contactNumber && errorCodes.contactNumberCode}
                    onChange={onChangeInptuOTP}
                />
                <Button
                    onClick={onSubmitOtpClick}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    hidden={!sentMail}
                >
                    Submit OTP
                </Button>
            </Box>
            <Box component="form" hidden={!otpSuccess} onSubmit={handleSubmitPassword} noValidate sx={{ mt: 1 }}>
                <h4>Reset Password</h4>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="password"
                    name="password"
                    type="password"
                    value={password}
                    autoFocus
                    error={errorCodes.password}
                    helperText={errorCodes.password && errorCodes.passwordCode}
                    onChange={onPasswordChange}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirm_password"
                    label="Confirm Password"

                    value={confirmPassword}
                    error={errorCodes.confirmPassword}
                    helperText={errorCodes.confirmPassword && errorCodes.confirmPasswordCode}
                    onChange={onConfirmPasswordChange}
                    id="confirm_password"
                />
                {logginErrors.show && <p className="text-danger">{logginErrors.message}</p>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Reset Password
                </Button>
            </Box>
        </Container>
    </div>)
}
export default ForgotPassword