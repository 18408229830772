import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { ShoppingCart, ShoppingCartCheckout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Figure } from 'react-bootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './products.css'
import axios from 'axios';

const imageStyle = {
  objectFit: 'cover',
  width: '100%',
  height: '100%'
};

export default function SaveForLaterProductCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const { productDetails } = props;
  const { productName, productRating, productID, productShortDesc, productPrice, productOfferPrice, productImage, id = 1 } = productDetails
  const userId = localStorage.getItem("userid");
  const onAddToCartClick = () => {
    alert("Adding intiated")
    postMoveToCartData();
  };
  const postMoveToCartData = () => {
    if (!localStorage.getItem("userid")) {
      navigate('/login')
      return
    }
    const urlInputs = {
      "main_id": "8989877hhftgh67",
      "user_id": localStorage.getItem("userid"),
    }

    axios.post('http://asvmall.com/api/cartItems.php', urlInputs).then(res => {
      let dbcartItems = res.data
      if (!Array.isArray(dbcartItems)) {
        dbcartItems = []
      }
      let count = 1
      let ourProductCount = count
      const sameItemsCheck = dbcartItems.filter((mapechItem) => (mapechItem.productId == productID))
      if (sameItemsCheck.length > 0) {
        ourProductCount = parseInt(sameItemsCheck[0].count) + count;
        const requestInputs = {
          "main_id": "8989877hhftgh67",
          "product_id": productID,
          "count": ourProductCount,
          "user_id": localStorage.getItem("userid")
        }
        axios.post("http://asvmall.com/api/updatecartitem.php", requestInputs).then(res => {
          alert("items added to cart successfully")
          navigate('/cart')
        }).catch((e) => {
          alert(e)
        })
      } else {
        const requestInputs = {
          "main_id": "8989877hhftgh67",
          "product_id": productID,
          "count": ourProductCount,
          "user_id": localStorage.getItem("userid")
        }
        axios.post("http://asvmall.com/api/addToCart.php", requestInputs).then(res => {
          alert("items added to cart successfully")
          axios.post("http://asvmall.com/api/saveforlater/removesaveditem.php", {
            "main_id": "8989877hhftgh67",
            "product_id": productID,
            "user_id": localStorage.getItem("userid")
          }).then(res => {
            if (res.data == 1) {
             
            }
          }).catch((e) => {
            // //console.log(e)
          })
          navigate('/cart')
        }).catch((e) => {
          //console.log("Error occured while adding items to cart", e)
        })
      }
    }).catch(e => {
      alert(e)
    })
  }
  const onProductCardClick = () => {
    navigate(`/products/${productID}`)
  }
  const sendRemoveSavedItemRequest = (productID, userId) => {
    const requestInputs = {
      "main_id": "8989877hhftgh67",
      "product_id": productID,
      "user_id": userId
    }
    axios.post("http://asvmall.com/api/saveforlater/removesaveditem.php", requestInputs).then(res => {
      alert("Item removed Succesfulyy")
    }).catch((e) => {
      //console.log(e)
    })
  }

  const onRemoveFromSaveLaterClick = () => {
    sendRemoveSavedItemRequest(productID, userId)
  }
  return (
    // <Card sx={{ maxWidth: '100%' }}  onClick={onProductCardClick}>
    <Card sx={{ maxWidth: '100%' }} >
      <Typography className="text-truncate pt-3 pb-3 ps-1">
        {productName}
      </Typography>
      <Figure className="d-flex justify-content-center" onClick={onProductCardClick}>
        <Figure.Image
          fluid={false}
          width={'100%'}
          //  height={"100%"}
          className="fig-image"
          src={productImage}
          alt={productName}
          style={{ objectFit: 'cover', ratio: 1 / 1 }}
          sx={imageStyle}
        />
      </Figure>
      <CardContent>
        <Typography variant="body2" color="text.dark">
          <p className='d-flext justify-content-between'> <s>Rs. {productPrice} /- </s>  <b>Rs. {productOfferPrice} /-</b></p>
          <span className='text-truncate'>{productShortDesc}   </span>
        </Typography>
      </CardContent>
      <CardActions disableSpacing >
        <Button className='w-100 ' variant="contained" color="info" startIcon={<ShoppingCart />} onClick={onAddToCartClick}>
          Move TO Cart
        </Button><br></br>
        <Button className='w-100 ' variant="contained" color="warning" startIcon={<DeleteForeverIcon />} onClick={onRemoveFromSaveLaterClick}>
          Remove
        </Button>
      </CardActions>
    </Card>
  );
}