import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AddNewAddressModal from './addnewAddressFrom';
import EditAdressDetails from './editaddressform';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function AddressModal2(props) {
  const { updatepage } = props
  const [show, setShow] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [userdetails, setUserdetails] = useState([]);
  const [addresslist, setAddressList] = useState([])
  const [updateUI, setUpdateUI] = useState(true)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleonsaveChanges = () => {

    axios.post("http://asvmall.com/api/address/updateaddressdefault.php", {
      "main_id": "8989877hhftgh67",
      "user_id": localStorage.getItem("userid"),
      "address_id": selectedAddress
    }).then((result) => {
      if (result.status === 200 && result.request.readyState === 4) {
        alert("Adress Updated Successfully in 2")
        if (updatepage) {
          updatepage();
          setUpdateUI(!updateUI)
        }
        window.location.reload();
      }
    }).catch("no")
    handleClose();
  }
  useEffect(() => {

    axios.post("http://asvmall.com/api/address/getaddresslist.php", {
      "main_id": "8989877hhftgh67",
      "user_id": localStorage.getItem("userid")
    }).then((result) => {

      if (result.status === 200 && result.request.readyState === 4 && Array.isArray(result.data)) {
        setAddressList(result.data)
        let filteredArr = result.data.filter((eachITem => eachITem.address_isdefault == 0))
        if (filteredArr.length > 0) {
          setSelectedAddress(filteredArr[0].address_id);
          setUserdetails(filteredArr[0])
        }
      }
    }).catch(
      () => {
        alert("Error Accored while getting the address details")
      }
    )
  }, [updateUI])

  return (
    <>
      {addresslist.length > 0 ?
        <Grid container  >
          <Grid item xs={12}  >
            <Item>
              <div className=" text-start p-3">
                <div className="d-flex align-items-center justify-content-between ">
                  <h5>Default Delivery Address</h5>
                  <Button className="ms-3" variant="primary" onClick={handleShow}>
                    Change
                  </Button>
                </div>
                <div className="mt-3 address-item d-flex align-items-center justify-content-between ">
                  <div>
                    <h6>  {userdetails.address_user_name}  - {userdetails.address_zip}<br />{userdetails.address_line_1}, {userdetails.address_line_2}  , {userdetails.address_city} </h6>
                  </div>
                  <EditAdressDetails addressDetails={userdetails} />
                </div>
              </div>
            </Item>
          </Grid>
          <div className="d-flex ">
            <AddNewAddressModal />
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Select Address</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {
                  addresslist.map((eachItem, index) => (
                    <ListItem alignItems="flex-start" key={eachItem.address_id}>
                      <ListItemText
                        primary={<Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body1"
                          color="text.primary"
                        >
                          {eachItem.address_user_name}  - {eachItem.address_mobile}</Typography>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {eachItem.address_line_1}, {eachItem.address_line_2}
                              {eachItem.address_city}, {eachItem.address_state}, {eachItem.address_country} - {eachItem.address_zip}
                            </Typography>

                          </React.Fragment>
                        }
                      />
                      <Radio
                        checked={selectedAddress === eachItem.address_id}
                        onChange={() => setSelectedAddress(eachItem.address_id)}
                        value="address2"
                      />
                    </ListItem>
                  ))
                }
              </List>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={() => handleonsaveChanges()}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Grid> :
        <Grid item xs={12} >
          <h6 className="text-danger">Address not added for your deliveries please add new delivery address by clicking the below</h6>
          <AddNewAddressModal addressList={addresslist} />
        </Grid>
      }
    </>
  );
}

export default AddressModal2;