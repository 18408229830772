import { useEffect, useState } from 'react'
import axios from 'axios'
import RecipeReviewCard from '../products/products';
import { Box, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import MyCarosal from '../products/mycarousal';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './homecomponent.css'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const HomeComponent = () => {
  const [productsList, setProductsList] = useState([]);
  const [recentProductsList, setRecentProductsList] = useState([]);
  const [seachValue, setSeachValue] = useState("");
  const [currentState, setCurrentState] = useState("LOADING")
  const navigate = useNavigate();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  function getFavoriteProducts(products, favoriteProducts) {
    // Validate input (optional but recommended)
    if (!Array.isArray(products) || !Array.isArray(favoriteProducts)) {
      throw new Error('Invalid input: products and favoriteProducts must be arrays.');
    }
    const stringFavoriteProducts = favoriteProducts.map(id => String(id));
    // Efficiently filter products using a Set for fast membership checks
    return products.filter(product => stringFavoriteProducts.includes(String(product.productID)));
  }

  useEffect(() => {
    axios.post("http://asvmall.com/api/products.php", {
      "main_id": "8989877hhftgh67"
    }).then(res => {
      if (Array.isArray(res.data)) {
        setProductsList(res.data)
        setCurrentState('SUCCESS')
      } else {
        setProductsList([]);
        setCurrentState('EMPTY')
      }
    }).catch(
      e => {
        setCurrentState('FAILED')
      }
    )

    axios.post("http://asvmall.com/api/advanced/recentlyViewed.php", {
      "main_id": "8989877hhftgh67",
      "user_id": localStorage.getItem("userid")
    }).then(res => {
      if (Array.isArray(res.data)) {
        let recentlyViewdList = JSON.parse(res.data[0].products_list)
        if (Array.isArray(recentlyViewdList)) {
          setRecentProductsList(recentlyViewdList)
          localStorage.setItem("recentProductsList", JSON.stringify(recentlyViewdList));
        }
      }
    }).catch(
      e => {
        //console.log("Error While fetching data", e)
        setCurrentState('FAILED')
      }
    )
  }, [])

  switch (currentState) {
    case 'LOADING':
      return (
        <div className="d-flex  align-items-center  justify-content-center text-center">
          <h1>Loading...</h1>
        </div>
      );
      break;
    case 'SUCCESS':
      return (
        // <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="align-items-center">
            <Grid item xs={12} >
              <MyCarosal /> 
            </Grid>
            <Grid item container  >
            {productsList && productsList.length > 0 &&
              <Grid item container xs={12} sm={6} md={4} className="d-flex p-3 ">
                <div className="d-flex flex-wrap ms-3 me-3 menu-cat-div color1 p-3">
              
        {productsList.slice(0, 4).map((product, index) => (
          <img
            key={index}
            className="w-50 p-3 menu-cat-img"
            onClick={() => navigate(`/products/${product.productID}`)}
            src={product.productImage}
            alt={`Product ${index + 1}`}
          />
        ))}
        {/* <p className="ms-3 btn hover-color-change-css" onClick={() => { navigate(`/productsbySubCatId/${productsList[0].productSubCategoryID}`) }}>Explore all</p> */}
    
                
                  <p className="ms-3 btn hover-color-change-css" onClick={() => { navigate(`/productsbySubCatId/${productsList[0].productSubCategoryID}`) }}>Explore all </p>
                </div>
              </Grid>
  }
  {productsList && productsList.length > 8 &&
              <Grid item container xs={12} sm={6} md={4} className="d-flex p-3 " >
                <div className="d-flex flex-wrap ms-3 me-3 menu-cat-div color2 p-3">
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[4].productID}`)} src={productsList[4].productImage} />
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[5].productID}`)} src={productsList[5].productImage} />
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[6].productID}`)} src={productsList[6].productImage} />
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[7].productID}`)} src={productsList[7].productImage} />
                  <p className="ms-3 btn hover-color-change-css" onClick={() => { navigate(`/productsbySubCatId/${productsList[4].productSubCategoryID}`) }}>Explore all </p>
                </div>
              </Grid>
  }
    {productsList && productsList.length > 12 &&
              <Grid item container xs={12} sm={6} md={4} className="d-flex p-3  d-none d-sm-none d-md-inline">
                <div className="d-flex flex-wrap ms-3 me-3 menu-cat-div color3 p-3">
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[8].productID}`)} src={productsList[8].productImage} />
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[9].productID}`)} src={productsList[9].productImage} />
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[10].productID}`)} src={productsList[10].productImage} />
                  <img className="w-50 p-3 menu-cat-img" onClick={() => navigate(`/products/${productsList[11].productID}`)} src={productsList[11].productImage} />
                  <p className="ms-3 btn hover-color-change-css" onClick={() => { navigate(`/productsbySubCatId/${productsList[8].productSubCategoryID}`) }}>Explore all </p>
                </div>
              </Grid>
  }
            </Grid>
          </Grid>
          {
            getFavoriteProducts(productsList, recentProductsList).length >= 3 &&
            <Grid container spacing={2}>
              <Grid item xs={12} nowrap={true} zeroMinWidth>
                <div className="d-flex align-items-end justify-content-between ps-3 pe-3">
                  <h1 className="mb-0">Recently Viewed</h1>
                  <h6 className="text-success hover-change-css" role="button" onClick={() => { navigate(`/productsbySubCatId/45`) }}>View All </h6>
                </div>
                <hr className="m-0"></hr>
              </Grid>
              <Grid item xs={12} nowrap={true} >
                <div className=" slider-div ">
                  <Slider {...settings} max_height slidesToShow={getFavoriteProducts(productsList, recentProductsList).length >= 6 ? 6 : getFavoriteProducts(productsList, recentProductsList).length} >
                    {
                      getFavoriteProducts(productsList, recentProductsList).map(each => (
                        <RecipeReviewCard productDetails={each} key={each.productID} />
                      ))
                    }
                  </Slider>
                </div>  </Grid>
            </Grid>
          }

          {
            productsList.filter(product => product.productSubCategoryID == 45).length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} nowrap={true} zeroMinWidth>
                <div className="d-flex align-items-end justify-content-between ps-3 pe-3">
                  <h1 className="mb-0">Rice & Atta</h1>
                  <h6 className="text-success hover-change-css" onClick={() => { navigate(`/productsbySubCatId/45`) }} role="button">View All </h6>
                </div>
                <hr className="m-0"></hr>
              </Grid>
              <Grid item xs={12} nowrap={true} >
                <div className=" slider-div ">
                  <Slider {...settings}>
                    {
                      productsList.filter(product => product.productSubCategoryID == 45).map(each => (
                        <RecipeReviewCard productDetails={each} key={each.productID} />
                      ))
                    }
                  </Slider>
                </div>  </Grid>
            </Grid>
          }
          {
            productsList.filter(product => product.productSubCategoryID == 43).length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} nowrap={true} zeroMinWidth>
                <div className="d-flex align-items-end justify-content-between ps-3 pe-3">
                  <h1 className="mb-0">Chocolates</h1>
                  <h6 className="text-success hover-change-css" onClick={() => { navigate(`/productsbySubCatId/43`) }} role="button">View All </h6>
                </div>
                <hr className="m-0"></hr>
              </Grid>
              <Grid item xs={12} nowrap={true} >
                <div className=" slider-div ">
                  <Slider {...settings}>
                    {
                      productsList.filter(product => product.productSubCategoryID == 43).map(each => (
                        //   <Grid item xs={12} sm={6} >
                        <RecipeReviewCard productDetails={each} key={each.productID} />
                        //  </Grid>
                      ))
                    }
                  </Slider>
                </div>  </Grid>
            </Grid>
          } {
            productsList.filter(product => product.productSubCategoryID == 54).length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} nowrap={true}  >
                <div className="d-flex align-items-end justify-content-between  ps-3 pe-3 ">
                  <h1 className="mb-0">Soaps</h1>
                  <h6 className="text-success hover-change-css" onClick={() => { navigate(`/productsbySubCatId/54`) }} role="button">View All </h6>
                </div>
                <hr className="m-0"></hr>
              </Grid>
              <hr className="m-0"></hr>
              <Grid item xs={12} nowrap={true} >
                <div className=" slider-div mb-5">
                  <Slider {...settings}>
                    {
                      productsList.filter(product => product.productSubCategoryID == 54).map(each => (
                        <RecipeReviewCard productDetails={each} key={each.productID} className="ps-3 pe-3" />
                      ))
                    }
                  </Slider>
                </div>
              </Grid>
            </Grid>
          }
          {
            productsList.filter(product => product.productSubCategoryID == 57).length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} nowrap={true}  >
                <div className="d-flex align-items-end justify-content-between  ps-3 pe-3 ">
                  <h1 className="mb-0">Furniture</h1>
                  <h6 className="text-success hover-change-css" onClick={() => { navigate(`/productsbySubCatId/57`) }} role="button">View All </h6>
                </div>
                <hr className="m-0"></hr>
              </Grid>
              <hr className="m-0"></hr>
              <Grid item xs={12} nowrap={true} >
                <div className=" slider-div mb-5">
                  <Slider {...settings}>
                    {
                      productsList.filter(product => product.productSubCategoryID == 57).map(each => (
                        <RecipeReviewCard productDetails={each} key={each.productID} className="ps-3 pe-3" />
                      ))
                    }
                  </Slider>
                </div>
              </Grid>

            </Grid>
          }
          {
            productsList.filter(product => product.productSubCategoryID == 60).length > 0 &&
            <Grid container spacing={2}>
              <Grid item xs={12} nowrap={true}  >
                <div className="d-flex align-items-end justify-content-between  ps-3 pe-3 ">
                  <h1 className="mb-0">Soaps</h1>
                  <h6 className="text-success hover-change-css" onClick={() => { navigate(`/productsbySubCatId/60`) }} role="button">View All </h6>
                </div>
                <hr className="m-0"></hr>
              </Grid>
              <hr className="m-0"></hr>
              <Grid item xs={12} nowrap={true} >
                <div className=" slider-div mb-5">
                  <Slider {...settings}>
                    {
                      productsList.filter(product => product.productSubCategoryID == 60).map(each => (
                        <RecipeReviewCard productDetails={each} key={each.productID} className="ps-3 pe-3" />
                      ))
                    }
                  </Slider>
                </div>
              </Grid>

            </Grid>
          }
        </Box>
      )
      break;
    case 'FAILED':
      return (
        <div className="d-flex  m-3  justify-content-center text-center">
          <div>
            <img className="w-75" src="https://t3.ftcdn.net/jpg/06/47/94/56/360_F_647945631_K1enp9zSJLMLKhzxyArhexgP5xLvDcb6.jpg" alt="server error" />
            <h1>Internal server error...</h1>
          </div>
        </div>);
    default:
      return (
        <div className="d-flex  m-3  justify-content-center text-center">
          <div>
            <img className="w-75" src="https://cdni.iconscout.com/illustration/premium/thumb/employee-is-unable-to-find-sensitive-data-9952946-8062130.png" alt="No data found" />
            <h1>No data found.....</h1>
          </div>
        </div>);
  }
}