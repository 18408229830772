import { useEffect, useState } from 'react';
import './categories.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    flexGrow: 1,
    color: theme.palette.text.secondary,
}));

const CategoriesPage = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([])
    const [selectedcategoryId, setSelectedcategoryId] = useState(19)
    const [selectedcategories, setSelectedcategories] = useState([])
    const [currentState, setCurrentState] = useState("INITIAL")
    const [selectedIndex, setSelectedIndex] = useState(1);

    const handleListItemClick = (event, index, each) => {
        setSelectedIndex(index);
        onCategoryClick(each)
    };

    const onCategoryClick = (eachCat) => {
        setSelectedcategoryId(eachCat.CategoryID)
    
        getSubCatItems(eachCat.CategoryID);
    }

    const onViewAllProducts = (catId) => {
        navigate(`/productsbySubCatId/${catId}`)
    }

    const getSubCatItems = (catId) => {
        axios.post('http://asvmall.com/api/subcategories.php', {
            "main_id": "8989877hhftgh67",
            "catId": catId
        }).then(result => {
            if (Array.isArray(result.data)) {
                setSelectedcategories(result.data)
            } else {
                setSelectedcategories([])
            }
        }).catch(e => {
            //console.log(e)
        })
    }

    const getTotaldetailsjsx = () => {
        return (<Grid container spacing={1}>
            <Grid item xs={5} sm={3} md={2} >
                <List component="nav" aria-label="Categorys" >
                    {categories.map((each) =>
                        <ListItemButton
                        key={each.CategoryID}
                            selected={selectedIndex == each.CategoryID}
                            onClick={(event) => handleListItemClick(event, each.CategoryID, each)}
                        >
                            <ListItemText primary={each.CategoryName} />
                        </ListItemButton>
                    )}
                </List>
            </Grid>
            <Grid item xs={7} sm={9} md={10} className="d-flex flex-wrap ">
                {selectedcategories.length > 0 ?
                    selectedcategories.map(each =>
                        <div className='sub-cat-div col-12 col-sm-6 col-md-3 col-lg-2'  key={each.SubcatName} onClick={e => onViewAllProducts(each.SubcatID)}>
                            <img className="sub-cat-images" src={each.subcatimage} />
                            <p className=' ps-3 pe-3 w-100 sub-cat-title  text-center text-truncate'>{each.SubcatName}</p>
                            {/* <p className='sub-cat-title text-truncate'>{each.SubcatName}</p> */}
                            <button className="btn btn-info"  >View All</button>
                        </div>) :
                    <Grid item xs={12}>
                        <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                            <img className="w-50" src="https://www.breathearomatherapy.com/assets/images/global/no-product.png" />
                            <p>No products for this category</p>
                        </div>
                    </Grid>
                }
            </Grid>
        </Grid>)
    }

    useEffect(() => {
        setCurrentState('LOADING')
        axios.post("http://asvmall.com/api/categories.php", {
            "main_id": "8989877hhftgh67"
        }).then(
            (result) => {
                if (Array.isArray(result.data)) {
                    setCategories(result.data)
                    setSelectedcategoryId(result.data[0].CategoryID)
                    getSubCatItems(result.data[0].CategoryID)
                    setCurrentState('COMPLETE')
                } else {
                    setCurrentState('EMPTY')
                }
            }
        ).catch(
            (error) => {
              
                setCurrentState('FAILED')
            }
        )
    }, [])

    switch (currentState) {
        case 'LOADING':
            return (
                <div className="d-flex  align-items-center  justify-content-center text-center">
                    <h1>Loading...</h1>
                </div>
            );
            break;
        case 'COMPLETE':
            return (getTotaldetailsjsx());
            break;
        case 'FAILED':
            return (
                <div className="d-flex  m-3  justify-content-center text-center">
                    <div>
                        <img className="w-75" src="https://t3.ftcdn.net/jpg/06/47/94/56/360_F_647945631_K1enp9zSJLMLKhzxyArhexgP5xLvDcb6.jpg" alt="server error" />
                        <h1>Internal server error</h1>
                    </div>
                </div>);
            break;
        default:
            return (<div className="d-flex   justify-content-center text-center">
                <div>
                    <img className="w-75" src="https://cdni.iconscout.com/illustration/premium/thumb/employee-is-unable-to-find-sensitive-data-9952946-8062130.png" alt="No data found" />
                    <h1>No data found.....</h1>
                </div>
            </div>)
    }
}
export default CategoriesPage;