
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AddressModal2 from './addressmodel2';
import AddNewAddressModal from './addnewAddressFrom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import AddressEditMenu from './editaddressform';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import EditAdressDetails from './editaddressform';
import './myAddress.css'
const MyAddress = () => {
    const [addresslist, setAddressList] = useState([])
    const [selectedAddress, setSelectedAddress] = useState([]);
    const [updatePage, setUpdatePage] = useState(false)

    const onPageUpdate = () => {
        setUpdatePage(!updatePage)
    }

    const onDeleteAdressClick = (eachItem) => {
        const { address_id, adress_user_id } = eachItem
        axios.post("http://asvmall.com/api/address/deleteaddressdetails.php", {
            "main_id": "8989877hhftgh67",
            "user_id": adress_user_id,
            "address_id": address_id
        }).then((result) => {
            if (result.data == 1) {
                alert("Address deleted ")
                setUpdatePage(!updatePage)
            } else {
                alert("invalid parameters leads to unexpected behaviour ")
            }
        }).catch(e => {
            //console.log(e)
        })
    }

    const onEditAdressDetailsClick = (eachItem) => {

    }
    useEffect(() => {

        axios.post("http://asvmall.com/api/address/getaddresslist.php", {
            "main_id": "8989877hhftgh67",
            "user_id": localStorage.getItem("userid")
        }).then((result) => {

            if (result.status === 200 && result.request.readyState === 4 && Array.isArray(result.data)) {
                let filteredArr = result.data.filter((eachITem => eachITem.address_isdefault != 0))
                setAddressList(filteredArr)
                setSelectedAddress(result.data.filter((eachITem => eachITem.address_isdefault == 0)));
            }
        }).catch("no")

    }, [updatePage])

    return (
        <Grid container className='p-3' >
            <Grid item xs={12} >
                <Grid item xs={12} className="pt-3">
                    <h4 >Manage Addresses</h4>
                    <AddressModal2 updatepage={onPageUpdate} />
                </Grid>
                <h4>My other Address</h4>
                {addresslist.length > 0 ?
                    <List sx={{ width: '100%', padding: '20px', bgcolor: 'background.paper' }}>
                        {
                            addresslist.map((eachItem, index) => (
                                <ListItem className="address-item" alignItems="flex-start" key={eachItem.address_id}>
                                    <ListItemText
                                        className="text-bold"
                                        primary={<Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body"
                                            color="text.primary"
                                        >
                                            {eachItem.address_user_name}  - {eachItem.address_mobile}</Typography>}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {eachItem.address_line_1}, {eachItem.address_line_2}
                                                    {eachItem.address_city}, {eachItem.address_state}, {eachItem.address_country} - {eachItem.address_zip}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                    <EditAdressDetails addressDetails={eachItem} />
                                    <DeleteForeverIcon role="button" className="ms-3" onClick={() => { onDeleteAdressClick(eachItem) }} />
                                </ListItem>
                            ))
                        }
                    </List>
                    :
                    <Grid item xs={12}  >
                        No Other Address in your profile You can Add New address for you freinds
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default MyAddress;