import { useEffect, useState } from 'react'
import axios from 'axios'
import RecipeReviewCard from './products';
import { Box, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import MyCarosal from './mycarousal';
import { useNavigate, useParams } from 'react-router-dom';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const ProductListComponent = (props) => {
  const [productsList, setProductsList] = useState([]);
  const [seachValue, setSeachValue] = useState("");
  const [currentState, setCurrentState] = useState("LOADING")
  const navigate = useNavigate();
  let { searchText } = useParams();
  if (!searchText) {
    searchText = ""
  }

  useEffect(() => {
    
    axios.post("http://asvmall.com/api/products.php", {
      "main_id": "8989877hhftgh67"
    }).then(res => {
      if (Array.isArray(res.data)) {
        setProductsList(res.data)
        setCurrentState('SUCCESS')
      } else {
        setProductsList([]);
        setCurrentState('EMPTY')
      }
    }).catch(
      e => {
        //console.log("Error While fetching data", e)
        setCurrentState('FAILED')
      }
    )
  }, [])

  switch (currentState) {
    case 'LOADING':
      return (
        <div className="d-flex  align-items-center  justify-content-center text-center">
          <h1>Loading...</h1>
        </div>
      );
      break;
    case 'SUCCESS':
      return (
        // <div>
        <Box sx={{ flexGrow: 1 }}>

         
          <Grid container spacing={2}>
            {
              productsList.filter(product => product.productName.toLowerCase().includes(searchText.toLowerCase()) || product.productLongDesc.toLowerCase().includes(searchText.toLowerCase())).map((each,ind) => (
                <Grid key={ind} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <RecipeReviewCard productDetails={each} key={each.productID} />
                </Grid>
              ))
            }
          </Grid>
        </Box>
      )
      break;
    case 'FAILED':
      return (
        <div className="d-flex  m-3  justify-content-center text-center">
          <div>
            <img className="w-75" src="https://t3.ftcdn.net/jpg/06/47/94/56/360_F_647945631_K1enp9zSJLMLKhzxyArhexgP5xLvDcb6.jpg" alt="server error" />
            <h1>Internal server error...</h1>
          </div>
        </div>);
    default:
      return (
        <div className="d-flex  m-3  justify-content-center text-center">
          <div>
            <img className="w-75" src="https://cdni.iconscout.com/illustration/premium/thumb/employee-is-unable-to-find-sensitive-data-9952946-8062130.png" alt="No data found" />
            <h1>No data found.....</h1>
          </div>
        </div>);
  }
}