import Grid from '@mui/material/Grid';
const InvalidUrlPage = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div className=" pt-3 w-100 d-flex  flex-column justify-content-center align-items-center">
                    <img classname="w-75" src="https://t4.ftcdn.net/jpg/05/77/76/77/360_F_577767795_M8RiapuzikM62dlNACcGVDHeiLq5HGVd.jpg" />
                    <p>Invalid URL, Please check URL and try again</p>
                </div>
            </Grid>
        </Grid>
    )
}

export default InvalidUrlPage;