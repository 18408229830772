import { useState } from "react";
import MyImageMag from "./mymagiczoom";
import Grid from '@mui/material/Grid';
import './productDetails.css'

const ImageGalleryWithMagicZoon = (props) => {
  const { productdetails } = props;
  const productImagesList = JSON.parse(productdetails.productImagesList);
  
  const [productmMainImg, setProductMainImg] = useState(productImagesList[0].product_media_link)
  const [productmMainImgType, setProductMainImgType] = useState(productImagesList[0].product_media_type)
  return (
    <Grid container spacing={2} className="align-items-center ">
      <Grid item h1 xs={2} className="d-flex flex-column flex-grow-0 image-thumbs-cntnr">
        {
          productImagesList.map((eachmedia, ind) => {
            return (eachmedia.product_media_type === "image" ?
              <img src={eachmedia.product_media_link} key={ind} alt="Product sample" onClick={(e) => { setProductMainImg(eachmedia.product_media_link); setProductMainImgType(eachmedia.product_media_type) }} /> : <video className=" mt-2 mb-2 w-100" onClick={(e) => { setProductMainImg(eachmedia.product_media_link); setProductMainImgType(eachmedia.product_media_type) }}>
                <source src={eachmedia.product_media_link} type="video/mp4" />
                Your browser does not support HTML video.
              </video>)
          })
        }
      </Grid>
      <Grid item h1 xs={10} className=" p-5 d-flex align-items-center justify-content-center d-none d-md-block">
        <div className="w-100  image-cntnr d-flex align-items-center">
          {productmMainImgType === 'image' ? <MyImageMag imageUrl={productmMainImg} /> : <video className="w-100s" controls>
            <source src={productmMainImg} type="video/mp4" />
            Your browser does not support HTML video.
          </video>}
        </div>
      </Grid>
      <Grid item h1 xs={10} className="d-flex align-items-center d-md-none  ">
        <div className="w-100 image-cntnr d-flex align-items-center">
          {
            productmMainImgType === 'image' ? <img className="d-md-none deatails-box" src={productmMainImg} alt="product images" /> : <video className="d-md-none deatails-box" controls>
              <source src={productmMainImg} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          }
        </div>
      </Grid>
    </Grid>
  )
}
export default ImageGalleryWithMagicZoon;
