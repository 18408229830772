import ReactImageMagnify from 'react-image-magnify';
import './productDetails.css'
const MyImageMag = (props) => {
    const { imageUrl } = props;
    return (
        <ReactImageMagnify   {...{
            smallImage: {
                alt: 'Wristwatch by Ted Baker London',
                isFluidWidth: true,
                src: imageUrl,
                width: 1000,
                height: 800
            },
            largeImage: {
                src: imageUrl,

                width: 1000,
                height: 800
            }
        }} className="deatails-box imagezindex w-75" />
    )
}

export default MyImageMag;