import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ReactWhatsapp from 'react-whatsapp';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import './profile.css';

const HelpAndSupport = () => {
  const [queryMail, setQueryMail] = React.useState("")
  const [queryNumb, setQueryNumb] = React.useState("")
  const [queryMsg, setQueryMsg] = React.useState("")
  const form = useRef();
  const formvalidate = () => {
    var isvalid = true;
    if (queryMail.length < 6) {
      alert("please enter proper mail address")
      isvalid = false;
    } else if (queryNumb.length !== 10) {
      alert("Please enter proper mobile number ")
      isvalid = false;
    } else if (setQueryMsg.length < 0) {
      alert("please enter your query in the message box")
      isvalid = false;
    }

    return isvalid;
  }

  const onFromSubmit = (e) => {
    e.preventDefault();
    if (!formvalidate()) {
      return
    }
    emailjs
      .sendForm('service_m3n3haj', 'template_9j0tegf', form.current, {
        publicKey: 'CqaQOLLbvNJ3YtOox',
      })
      .then(
        () => {
          //console.log('SUCCESS!');
        },
        (error) => {
          //console.log('FAILED...', error.text);
        }
      );
    setQueryMail("")
    setQueryNumb("")
    setQueryMsg("")
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} className="align-center justify-content-center">
        <Grid item xs={12} md={6} className="d-md-none">
          <Typography className="text-center text-warning p-3 " variant="h6" gutterBottom>
            24 X 7 Customer support contact on <br></br>
            <CallIcon /> 9030942852
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} container className="justify-content-center align-items-center">
          <Grid item xs={12} md={6} className=" d-none d-md-block">
            <Typography className="text-center text-warning p-3 " variant="h6" gutterBottom>
              24 X 7 Customer support contact on <br></br>
              <CallIcon /> 9030942852
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} >
            <img className="w-100 ps-5 pe-5" src="https://teambuilding.com/wp-content/uploads/2023/02/customer-service-companies.jpg" />
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} container className="justify-content-center align-items-center ">
          <Grid item xs={12} md={6} className="text-center d-md-none"> <ReactWhatsapp className=" text-center btn btn-outline-success " style={{ border: "none" }} number="+91   9030942852" message="Hello ASv Mall support team,\n I have a Query" >
            <Typography className="text-center" variant="h6" gutterBottom>
              Click Here to chat on <br></br>
              <WhatsAppIcon /> Whatsapp
            </Typography>
          </ReactWhatsapp></Grid>
          <Grid item xs={12} md={6}  >
            <img className="w-100 p-3" src="https://cdn.favouritehub.com/wp-content/uploads/2021/01/Whatsapp-Chat-Icon-1.png" />
          </Grid>
          <Grid item xs={12} md={6} className="text-center d-none d-md-block"> <ReactWhatsapp className=" text-center btn btn-outline-success " style={{ border: "none" }} number="+91   9030942852" message="Hello ASv Mall support team, I have a Query" >
            <Typography className="text-center" variant="h6" gutterBottom>
              Click Here to chat on <br></br>
              <WhatsAppIcon /> Whatsapp
            </Typography>
          </ReactWhatsapp></Grid>
        </Grid>
        <Grid item xs={12} md={6} className="text-center d-md-none">
          <Typography className="text-center" variant="h6" gutterBottom>
            Send your queries or suggestions
            <br></br><EmailIcon />  to our team  <br></br>
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} container className="justify-content-center align-items-center ">
          <Grid item xs={12} md={6} className="text-center d-none d-md-block">
            <Typography className="text-center" variant="h6" gutterBottom>
              Send your queries or suggestions
              <br></br><EmailIcon />  to our team  <br></br>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}  >
            <form ref={form} onSubmit={onFromSubmit} className="d-flex flex-column  form-bgh" >
              <div className="d-flex flex-column">
                <label>Email</label>
                <input type="email" name="user_email" value={queryMail} onChange={(e) => setQueryMail(e.target.value)} />
              </div >
              <div className="d-flex flex-column">
                <label>contact number</label>
                <input type="number" name="user_numb" value={queryNumb} onChange={(e) => setQueryNumb(e.target.value)} />
              </div >
              <div className="d-flex flex-column">
                <label>Message</label>
                <textarea name="message" value={queryMsg} onChange={(e) => setQueryMsg(e.target.value)} />
              </div>
              <input type="submit" className="btn btn-primary mt-3" value="Submit" />
            </form></Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default HelpAndSupport;