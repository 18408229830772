import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './offcanvaexample.css'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemAvatar from '@mui/material/ListItemAvatar';

function OffCanvaExample(props) {
  const { sellerList, selectedIndex, handleListItemClickprop } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleListItemClick = (event, index, each) => {
    handleListItemClickprop(event, index, each)
    handleClose();
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <MenuIcon />
      </Button>
      Shop By Store Selection
      <Offcanvas show={show} onHide={handleClose} className="width80">
        <Offcanvas.Header closeButton className="bg-primary">
          <Offcanvas.Title>Choose Seller</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} >
                Shop By store selection
              </Grid>
              <Grid item xs={12} >
                <List component="nav" aria-label="Categorys" >
                  {sellerList.map((each,ind) =>
                    <ListItemButton
                    key={ind}
                      selected={selectedIndex == each.seller_id}
                      onClick={(event) => handleListItemClick(event, each.seller_id, each)}
                    >
                      <ListItemAvatar>
                        <Avatar>{each.seller_name[0]}</Avatar>
                      </ListItemAvatar>
                      <ListItemText  >{each.seller_name}</ListItemText>
                    </ListItemButton>
                  )}
                </List>
              </Grid>
            </Grid>
          </Box>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvaExample;