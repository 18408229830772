import * as React from 'react';
import { useNavigate } from 'react-router-dom';
// MUI imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AccountCircle, ShoppingCartCheckout } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import mainlog from './asvmallcolorwhite.png'
export default function ButtonAppBar() {
  const navigate = useNavigate();
  const [seachValue, setSeachValue] = React.useState("");

  return (
    <Box sx={{ flexGrow: 1, zIndex: 100 }} className>
      <AppBar position="static" sx={{ zIndex: 100 }}>
        <Toolbar>
        <img  
        width={200}
        src={mainlog}
       
      /> 
            <Typography className=" d-none d-md-block" variant="h6" component="div" sx={{ flexGrow: 3 }} >
            <Typography className=" d-none d-md-block" variant="h6" component="div"  >
              {/* <span onClick={() => navigate("/")} role="button">ASVmall</span> */}
            </Typography>
          </Typography>
         <Typography className=" d-block d-md-none" variant="h6" component="div" sx={{ flexGrow: 3 }} >
            <Typography className=" d-block d-md-none" variant="h6" component="div"  >
              {/* ASV */}
            </Typography>
          </Typography> 
          
          <div className="search-bar-div">
            <span className="input-group-text" id="basic-addon1"><SearchIcon /></span>
            <input type="search" className="form-control search-bar" placeholder="search here...."
              aria-label="searchbar" aria-describedby="basic-addon1" onChange={e => navigate(`/viewproducts/${e.target.value}`)} />
          </div>
          <IconButton size="large " className="pe-0 pe-md-3" onClick={() => navigate("/cart")} aria-label="search" color="inherit">
            <ShoppingCartCheckout />
          </IconButton>
          <IconButton size="large " className="ps-2" onClick={() => navigate("/Profiles")} aria-label="search" color="inherit">
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}