import { BrowserRouter } from 'react-router-dom';
import './App.css';
import SearchAppBar from './components/appbar/appbar';
import FixedBottomNavigation from './components/menu/menu';

function App() {
  return (
    <div className='' >
      <header >
        <BrowserRouter>
          <SearchAppBar />
          <FixedBottomNavigation />
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
